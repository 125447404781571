import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.scss'
import { ToastContainer } from 'react-toastify';
import Loader from  "components/Loader";

// Containers
const Layout = React.lazy(() => import('containers/Layout'));
class App extends Component {

  render() {
    return (
      <>
        <Router>
          <React.Suspense fallback={Loader}>
            <Switch>
              <Route path="/" component={Layout} />
            </Switch>
          </React.Suspense>
        </Router>
        <ToastContainer />
      </>
    );
  }
}

export default App;
