import Types from './../Types';

const initialState = {
    user:null,
    loading: false,
    success: false,
    error: null,
    message: null,
    update: { loading: false, success: false, error: null }
}


export default function entryUser(state = initialState, action: any) {
    switch (action.type) {
        case Types.GET_USER_LOADING:
            return {
                ...state, loading: true, success: false
            }
        case Types.GET_USER_SUCCESS:
            return {
                ...state, loading: false, success: true, user: action.payload, 
            }
        case Types.GET_USER_ERROR:
            return {
                ...state, loading: false, user: null
            }
        case Types.UPDATE_USER_LOADING:
            return {
                ...state, update: { loading: true, success: false }
            }
        case Types.UPDATE_USER_SUCCESS:
            return {
                ...state, update: { loading: false, success: true }
            }
        case Types.UPDATE_USER_ERROR:
            return {
                ...state, update: { loading: false }
            }

        case Types.CLEAR_USER_MESSAGE:
            return {
                ...state, message: null
            }
        case Types.RESET_APP:
            return {
                ...initialState
            }
        default:
            return state
    }

}