import Types from './../Types';

const initialState = {
    cities: { data: [], loading: false, success: false, error: null },
    countries: { data: [], loading: false, success: false, error: null },
    awardCategories: { data: [], loading: false, success: false, error: null },
    developmentType: { data: [], loading: false, success: false, error: null },
    locations: { data: [], loading: false, success: false, error: null },
    residentialType: { data: [], loading: false, success: false, error: null },
    greenType: { data: [], loading: false, success: false, error: null },
    awardCriteria: { data: [], loading: false, success: false, error: null },
    users: { data: [], loading: false, success: false, error: null },
    currency:{data: [], loading: false, success: false, error: null},
    selectedCurrency: null,
    measurement:{data: [], loading: false, success: false, error: null},
    selectedMeasurement: null,
    awardTypes: { data: [], loading: false, success: false, error: null },
    grandFinalCategories: { data: [], loading: false, success: false, error: null },
}


export default function entryUser(state = initialState, action: any) {
    switch (action.type) {

        case Types.GET_COUNTRIES_LOADING:
            return {
                ...state, countries: { ...state.countries, loading: true, success: false }
            }
        case Types.GET_COUNTRIES_SUCCESS:
            return {
                ...state, countries: { ...state.countries, loading: false, success: true, data: action.payload }
            }
        case Types.GET_COUNTRIES_ERROR:
            return {
                ...state, countries: { ...state.countries, loading: false }
            }

            case Types.GET_CITIES_LOADING:
                return {
                    ...state, cities: { ...state.cities, loading: true, success: false }
                }
            case Types.GET_CITIES_SUCCESS:
                return {
                    ...state, cities: { ...state.cities, loading: false, success: true, data: action.payload }
                }
            case Types.GET_CITIES_ERROR:
                return {
                    ...state, cities: { ...state.cities, loading: false }
                }

        case Types.GET_AWARD_CATEGORY_LOADING:
            return {
                ...state, awardCategories: { ...state.awardCategories, loading: true, success: false }
            }
        case Types.GET_AWARD_CATEGORY_SUCCESS:
            return {
                ...state, awardCategories: { ...state.awardCategories, loading: false, success: true, data: action.payload }
            }
        case Types.GET_AWARD_CATEGORY_ERROR:
            return {
                ...state, awardCategories: { ...state.awardCategories, loading: false }
            }

        case Types.GET_GRANDFINAL_CATEGORIES_LOADING:
            return {
                ...state, grandFinalCategories: { ...state.grandFinalCategories, loading: true, success: false }
            }
        case Types.GET_GRANDFINAL_CATEGORIES_SUCCESS:
            return {
                ...state, grandFinalCategories: { ...state.grandFinalCategories, loading: false, success: true, data: action.payload }
            }
        case Types.GET_GRANDFINAL_CATEGORIES_ERROR:
            return {
                ...state, grandFinalCategories: { ...state.grandFinalCategories, loading: false }
            }
            

        case Types.GET_DEVELOPMENT_TYPE_LOADING:
            return {
                ...state, developmentType: { ...state.countries, loading: true, success: false }
            }
        case Types.GET_DEVELOPMENT_TYPE_SUCCESS:
            return {
                ...state, developmentType: { ...state.countries, loading: false, success: true, data: action.payload }
            }
        case Types.GET_DEVELOPMENT_TYPE_ERROR:
            return {
                ...state, developmentType: { ...state.countries, loading: false }
            }
        case Types.GET_LOCATIONS_LOADING:
            return {
                ...state, locations: { ...state.locations, loading: true, success: false }
            }
        case Types.GET_LOCATIONS_SUCCESS:
            return {
                ...state, locations: { ...state.locations, loading: false, success: true, data: action.payload }
            }
        case Types.GET_LOCATIONS_ERROR:
            return {
                ...state, locations: { ...state.locations, loading: false }
            }
        case Types.GET_RESIDENTIAL_LOADING:
            return {
                ...state, residentialType: { ...state.residentialType, loading: true, success: false }
            }
        case Types.GET_RESIDENTIAL_SUCCESS:
            return {
                ...state, residentialType: { ...state.residentialType, loading: false, success: true, data: action.payload }
            }
        case Types.GET_RESIDENTIAL_ERROR:
            return {
                ...state, residentialType: { ...state.residentialType, loading: false }
            }
        case Types.GET_GREEN_TYPE_LOADING:
            return {
                ...state, greenType: { ...state.greenType, loading: true, success: false }
            }
        case Types.GET_GREEN_TYPE_SUCCESS:
            return {
                ...state, greenType: { ...state.greenType, loading: false, success: true, data: action.payload }
            }
        case Types.GET_GREEN_TYPE_ERROR:
            return {
                ...state, greenType: { ...state.greenType, loading: false }
            }
        case Types.GET_AWARD_CRITERIA_LOADING:
            return {
                ...state, awardCriteria: { ...state.awardCriteria, loading: true, success: false }
            }
        case Types.GET_AWARD_CRITERIA_SUCCESS:
            return {
                ...state, awardCriteria: { ...state.awardCriteria, loading: false, success: true, data: action.payload }
            }
        case Types.GET_AWARD_CRITERIA_ERROR:
            return {
                ...state, awardCriteria: { ...state.awardCriteria, loading: false }
            }

        case Types.GET_USERS_LOADING:
            return {
                ...state, users: { ...state.awardCriteria, loading: true, success: false }
            }
        case Types.GET_USERS_SUCCESS:
            return {
                ...state, users: { ...state.awardCriteria, loading: false, success: true, data: action.payload }
            }
        case Types.GET_USERS_ERROR:
            return {
                ...state, users: { ...state.awardCriteria, loading: false }
            }
        case Types.GET_CURRENCIES_LOADING:
            return {
                ...state, currency: { ...state.currency, loading: true, success: false }
            }
        case Types.GET_CURRENCIES_SUCCESS:
            return {
                ...state, currency: { ...state.currency, loading: false, success: true, data: action.payload }
            }
        case Types.GET_CURRENCIES_ERROR:
            return {
                ...state, currency: { ...state.currency, loading: false }
            }
        case Types.GET_AWARD_TYPES_LOADING:
            return {
                ...state, awardTypes: { ...state.awardTypes, loading: true, success: false }
            }
        case Types.GET_AWARD_TYPES_SUCCESS:
            return {
                ...state, awardTypes: { ...state.awardTypes, loading: false, success: true, data: action.payload }
            }
        case Types.GET_AWARD_TYPES_ERROR:
            return {
                ...state, awardTypes: { ...state.awardTypes, loading: false }
            }
        
        case Types.GET_MEASUREMENT_LOADING:
            return {
                ...state, measurement: { ...state.measurement, loading: true, success: false }
            }
        case Types.GET_MEASUREMENT_SUCCESS:
            return {
                ...state, measurement: { ...state.measurement, loading: false, success: true, data: action.payload }
            }
        case Types.GET_MEASUREMENT_ERROR:
            return {
                ...state, measurement: { ...state.measurement, loading: false }
            }
            
        case Types.SET_CURRENCY_SELECTED_SUCCESS:
            return {
                ...state, selectedCurrency: action.payload
            }

        case Types.SET_MEASUREMENT_SELECTED_SUCCESS:
            return {
                ...state, selectedMeasurement: action.payload
            }

        case Types.RESET_APP:
            return {
                ...initialState
            }
        default:
            return state
    }

}