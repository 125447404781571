import Types from './../Types';

const initialState = {
    entryList: [],
    totalRecord: 0,
    entryPageConfig: { page: 1, pageSize: 100 },
    entryDataPageConfig: { page: 1, pageSize: 100, entrystatus: 2 },
    prevEntryList: [],
    prevTotalRecord: 0,
    shortEntryList: [],
    shortTotalRecord: 0,
    entry: {},
    answers: [],
    questions: [],
    projectQuestions: [],
    projectProfile: [],
    scores: [],
    loading: false,
    success: false,
    error: null,
    message: null,
    delete: { loading: false, success: false, error: null },
    create: { loading: false, success: false, error: null },
    update: { loading: false, success: false, error: null },
    updateAnswer: { loading: false, success: false, error: null },
    updateScore: { loading: false, success: false, error: null },
    validateEntryForm: { loading: false, success: false, error: null ,data:null },
    addProject: { loading: false, success: false, error: null }
}

export default function entryReducer(state = initialState, action: any) {
    console.log("action in entry reducer", action)
    switch (action.type) {
        case Types.GET_ENTRIES_LOADING:
            return {
                ...state, loading: true, success: false
            }
        case Types.GET_ENTRIES_SUCCESS:
            return {
                ...state, loading: false, success: true, entryList: action.payload.data, totalRecord: action.payload.totalRecord
            }
        case Types.GET_ENTRIES_ERROR:
            return {
                ...state, loading: false, entryList: []
            }
        case Types.GET_PREV_ENTRIES_LOADING:
            return {
                ...state, loading: true, success: false
            }
        case Types.GET_PREV_ENTRIES_SUCCESS:
            return {
                ...state, loading: false, success: true, prevEntryList: action.payload.data, prevTotalRecord: action.payload.totalRecord
            }
        case Types.GET_PREV_ENTRIES_ERROR:
            return {
                ...state, loading: false, prevEntryList: []
            }
        case Types.GET_SHORTLISTED_ENTRIES_LOADING:
            return {
                ...state, loading: true, success: false
            }
        case Types.GET_SHORTLISTED_ENTRIES_SUCCESS:
            return {
                ...state, loading: false, success: true, shortEntryList: action.payload.data, shortTotalRecord: action.payload.totalRecord
            }
        case Types.GET_SHORTLISTED_ENTRIES_ERROR:
            return {
                ...state, loading: false, shortEntryList: []
            }
        case Types.GET_ENTRY_LOADING:
            return {
                ...state, loading: true, success: false, entry: {}
            }
        case Types.GET_ENTRY_SUCCESS:
            console.log("dta in get entry succewss", action.payload)
            return {
                ...state, loading: false, success: true, entry: action.payload
            }
        case Types.GET_ENTRY_ERROR:
            return {
                ...state, loading: false, entry: {}
            }
        case Types.GET_ENTRY_QUESTIONS_LOADING:
            return {
                ...state, loading: true, success: false
            }
        case Types.GET_ENTRY_QUESTIONS_SUCCESS:
            return {
                ...state, loading: false, success: true, questions: action.payload
            }
        case Types.GET_ENTRY_QUESTIONS_ERROR:
            return {
                ...state, loading: false, questions: []
            }
        case Types.GET_PROJECT_QUESTIONS_LOADING:
            return {
                ...state, loading: true, success: false
            }
        case Types.GET_PROJECT_QUESTIONS_SUCCESS:
            return {
                ...state, loading: false, success: true, projectQuestions: action.payload
            }
        case Types.GET_PROJECT_QUESTIONS_ERROR:
            return {
                ...state, loading: false, projectQuestions: []
            }
        case Types.GET_ENTRY_PROJECT_PROFILE_LOADING:
            return {
                ...state, loading: true, success: false
            }
        case Types.GET_ENTRY_PROJECT_PROFILE_SUCCESS:
            return {
                ...state, loading: false, success: true, projectProfile: action.payload
            }
        case Types.GET_ENTRY_PROJECT_PROFILE_ERROR:
            return {
                ...state, loading: false, projectProfile: []
            }
        case Types.GET_ENTRY_SCORES_LOADING:
            return {
                ...state, loading: true, success: false
            }
        case Types.GET_ENTRY_SCORES_SUCCESS:
            return {
                ...state, loading: false, success: true, scores: action.payload
            }
        case Types.GET_ENTRY_SCORES_ERROR:
            return {
                ...state, loading: false, scores: []
            }
        case Types.UPDATE_ENTRY_ANSWER_LOADING:
            return {
                ...state, updateAnswer: { loading: true, success: false }
            }
        case Types.UPDATE_ENTRY_ANSWER_SUCCESS:
            return {
                ...state, updateAnswer: { loading: false, success: true }
            }
        case Types.UPDATE_ENTRY_ANSWER_ERROR:
            return {
                ...state, updateAnswer: { loading: false }
            }
        case Types.ADD_PROJECT_LOADING:
            return {
                ...state, addProject: { loading: true, success: false }
            }
        case Types.ADD_PROJECT_SUCCESS:
            return {
                ...state, addProject: { loading: false, success: true }
            }
        case Types.ADD_PROJECT_ERROR:
            return {
                ...state, addProject: { loading: false }
            }
        case Types.VALIDATE_ENTRYFORM_LOADING:
            return {
                ...state, validateEntryForm: { loading: true, success: false }
            }
        case Types.VALIDATE_ENTRYFORM_SUCCESS:
            return {
                ...state, validateEntryForm: { loading: false, success: true }
            }
        case Types.VALIDATE_ENTRYFORM_ERROR:
            return {
                ...state, validateEntryForm: { loading: false }
            }
        case Types.UPDATE_ENTRY_SCORE_LOADING:
            return {
                ...state, updateScore: { loading: true, success: false }
            }
        case Types.UPDATE_ENTRY_SCORE_SUCCESS:
            return {
                ...state, updateScore: { loading: false, success: true }
            }
        case Types.UPDATE_ENTRY_SCORE_ERROR:
            return {
                ...state, updateScore: { loading: false }
            }
        case Types.UPDATE_ENTRY_LOADING:
            return {
                ...state, update: { loading: true, success: false }
            }
        case Types.UPDATE_ENTRY_SUCCESS:
            return {
                ...state, update: { loading: false, success: true }
            }
        case Types.UPDATE_ENTRY_ERROR:
            return {
                ...state, update: { loading: false }
            }
        case Types.DELETE_ENTRY_LOADING:
            return {
                ...state, delete: { loading: true, success: false }
            }
        case Types.DELETE_ENTRY_SUCCESS:
            return {
                ...state, delete: { loading: false, success: true }
            }
        case Types.DELETE_ENTRY_ERROR:
            return {
                ...state, delete: { loading: false }
            }
        case Types.CLEAR_ENTRY_MESSAGE:
            return {
                ...state, message: null
            }
        case Types.RESET_APP:
            return {
                ...initialState
            }

        
        default:
            return state
    }

}