import Types from './../Types';

const initialState = {
    login: { user: null, loading: false, success: false, error: null },
    signup: { user: null, loading: false, success: false, error: null },
    logout: { loading: false, success: false, error: null },
    forgotPassword: { loading: false, success: false, error: null, user: null },
    verify: { loading: false, success: false, error: null, user: null },
    resetPassword: { loading: false, success: false, error: null, user: null },

    message: null
}


export default function authReducer(state = initialState, action: any) {
    switch (action.type) {
        case Types.USER_LOGIN_LOADING:
            return {
                ...state, login: { loading: true, success: false, user: null }
            }
        case Types.USER_LOGIN_SUCCESS:
            return {
                ...state, login: { loading: false, success: true, user: action.payload }
            }
        case Types.USER_LOGIN_ERROR:
            return {
                ...state, login: { loading: false, user: null }
            }
        case Types.USER_SIGNUP_LOADING:
            return {
                ...state, signup: { loading: true, success: false, user: null }
            }
        case Types.USER_SIGNUP_SUCCESS:
            return {
                ...state, signup: { loading: false, success: true, user: action.payload }
            }
        case Types.USER_SIGNUP_ERROR:
            return {
                ...state, signup: { loading: false, user: null }
            }
        case Types.USER_LOGOUT_LOADING:
            return {
                ...state, logout: { loading: true, success: false }
            }
        case Types.USER_LOGOUT_SUCCESS:
            return {
                ...state, logout: { loading: false, success: true }
            }
        case Types.USER_LOGOUT_ERROR:
            return {
                ...state, logout: { loading: false }
            }

        case Types.USER_FORGOT_PASSWORD_LOADING:
            return {
                ...state, forgotPassword: { loading: true, success: false, user: null }
            }
        case Types.USER_FORGOT_PASSWORD_SUCCESS:
            return {
                ...state, forgotPassword: { loading: false, success: true, user: action.payload }
            }
        case Types.USER_FORGOT_PASSWORD_ERROR:
            return {
                ...state, forgotPassword: { loading: false }
            }
        case Types.USER_VERIFY_LOADING:
            return {
                ...state, verify: { loading: true, success: false, user: null }
            }
        case Types.USER_VERIFY_SUCCESS:
            return {
                ...state, verify: { loading: false, success: true, user: action.payload }
            }
        case Types.USER_VERIFY_ERROR:
            return {
                ...state, verify: { loading: false }
            }

        case Types.USER_RESET_PASSWORD_LOADING:
            return {
                ...state, resetPassword: { loading: true, success: false }
            }
        case Types.USER_RESET_PASSWORD_SUCCESS:
            return {
                ...state, resetPassword: { loading: false, success: true }
            }
        case Types.USER_RESET_PASSWORD_ERROR:
            return {
                ...state, resetPassword: { loading: false }
            }
        case Types.CLEAR_USER_AUTH_MESSAGE:
            return {
                ...state, message: null
            }
        case Types.RESET_APP:
            return {
                ...initialState
            }
        default:
            return state
    }
}