import Types from './../Types';

const initialState = {
    loading: false,
    success: false,
    error: null,
    message: null,
    sidebarShow: 'responsive'
}


export default function appReducer(state = initialState, action:any) {
    switch (action.type) {
        case Types.APP_LOADING:
            return {
                ...state, loading: true, success: false
            }
        case Types.APP_LOADING_SUCCESS:
            return {
                ...state, loading: false, success: true
            }
        case Types.APP_LOADING_ERROR:
            return {
                ...state, loading: false
            }

        case Types.CLEAR_APP_MESSAGE:
            return {
                ...state, message: null
            }
        case Types.RESET_APP:
            return {
                ...initialState
            }
        case Types.APP_SIDEBAR_SHOW:
            return { ...state, ...action }
        default:
            return state
    }
}