import app from './App';
import auth from './Auth';
import entry from './Entry';
import user from "./User";
import common from "./Common";


export default {
    app,
    auth,
    entry,
    user,
    common
}