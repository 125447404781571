import React from 'react'
import Loader from "react-loader-spinner";

interface ILoader {
    variant: 'small' | 'fullScreen' | 'overlay'
}

export default function CustomLoader(props: ILoader) {
    if (props.variant === 'small') {
        return (
            <div className="spinner-wrapper  spinner-wrapper-small ml-6rem">
                <Loader
                    type="TailSpin"
                    color="#BD9C2F"
                    height={20}
                    width={20}
                />
            </div>
        )
    }
    if (props.variant === 'overlay') {
        return (
            <div className="spinner-wrapper  spinner-wrapper-overlay">
                {/* <div className="spinner"></div> */}
                <Loader
                    type="TailSpin"
                    color="#BD9C2F"
                    height={50}
                    width={50}
                />
            </div>
        )
    }
    return (
        <div className="spinner-wrapper">
            <Loader
                type="TailSpin"
                color="#BD9C2F"
                height={50}
                width={50}
            />
        </div>
    )
}

CustomLoader.defaultProps = {
    variant: 'fullScreen'
}
