const Types = {

    RESET_APP: undefined,
    APP_SIDEBAR_SHOW: undefined,

    // user login

    USER_LOGIN_LOADING: undefined,
    USER_LOGIN_SUCCESS: undefined,
    USER_LOGIN_ERROR: undefined,

     // user Signup

     USER_SIGNUP_LOADING: undefined,
     USER_SIGNUP_SUCCESS: undefined,
     USER_SIGNUP_ERROR: undefined,

     //user confirm signup

     USER_SIGNUP_CONFIRM_LOADING: undefined,
     USER_SIGNUP_CONFIRM_SUCCESS: undefined,
     USER_SIGNUP_CONFIRM_ERROR: undefined,


    // user logout

    USER_LOGOUT_LOADING: undefined,
    USER_LOGOUT_SUCCESS: undefined,
    USER_LOGOUT_ERROR: undefined,

    // user forgot Password

    USER_FORGOT_PASSWORD_LOADING: undefined,
    USER_FORGOT_PASSWORD_SUCCESS: undefined,
    USER_FORGOT_PASSWORD_ERROR: undefined,

    // user Verify

    USER_VERIFY_LOADING: undefined,
    USER_VERIFY_SUCCESS: undefined,
    USER_VERIFY_ERROR: undefined,

    // user reset Password

    USER_RESET_PASSWORD_LOADING: undefined,
    USER_RESET_PASSWORD_SUCCESS: undefined,
    USER_RESET_PASSWORD_ERROR: undefined,

    CLEAR_USER_AUTH_MESSAGE: undefined,


    // App

    APP_LOADING: undefined,
    APP_LOADING_SUCCESS: undefined,
    APP_LOADING_ERROR: undefined,
    CLEAR_APP_MESSAGE: undefined,

    // users
    GET_USERS_LOADING: undefined,
    GET_USERS_SUCCESS: undefined,
    GET_USERS_ERROR: undefined,

    GET_USER_LOADING: undefined,
    GET_USER_SUCCESS: undefined,
    GET_USER_ERROR: undefined,

    UPDATE_USER_LOADING: undefined,
    UPDATE_USER_SUCCESS: undefined,
    UPDATE_USER_ERROR: undefined,

    CLEAR_USER_MESSAGE: undefined,

    // Entries 
    GET_ENTRIES_LOADING: undefined,
    GET_ENTRIES_SUCCESS: undefined,
    GET_ENTRIES_ERROR: undefined,

    GET_PREV_ENTRIES_LOADING: undefined,
    GET_PREV_ENTRIES_SUCCESS: undefined,
    GET_PREV_ENTRIES_ERROR: undefined,

    GET_SHORTLISTED_ENTRIES_LOADING: undefined,
    GET_SHORTLISTED_ENTRIES_SUCCESS: undefined,
    GET_SHORTLISTED_ENTRIES_ERROR: undefined,

    GET_ENTRY_LOADING: undefined,
    GET_ENTRY_SUCCESS: undefined,
    GET_ENTRY_ERROR: undefined,

    CREATE_ENTRY_LOADING: undefined,
    CREATE_ENTRY_SUCCESS: undefined,
    CREATE_ENTRY_ERROR: undefined,

    PUT_ENTRY_LOADING: undefined,
    PUT_ENTRY_SUCCESS: undefined,
    PUT_ENTRY_ERROR: undefined,

    UPDATE_ENTRY_LOADING: undefined,
    UPDATE_ENTRY_SUCCESS: undefined,
    UPDATE_ENTRY_ERROR: undefined,

    DELETE_ENTRY_LOADING: undefined,
    DELETE_ENTRY_SUCCESS: undefined,
    DELETE_ENTRY_ERROR: undefined,

    GET_ENTRY_QUESTIONS_LOADING: undefined,
    GET_ENTRY_QUESTIONS_SUCCESS: undefined,
    GET_ENTRY_QUESTIONS_ERROR: undefined,

    GET_PROJECT_QUESTIONS_LOADING: undefined,
    GET_PROJECT_QUESTIONS_SUCCESS: undefined,
    GET_PROJECT_QUESTIONS_ERROR: undefined,

    GET_ENTRY_PROJECT_PROFILE_LOADING: undefined,
    GET_ENTRY_PROJECT_PROFILE_SUCCESS: undefined,
    GET_ENTRY_PROJECT_PROFILE_ERROR: undefined,

    UPDATE_ENTRY_ANSWER_LOADING: undefined,
    UPDATE_ENTRY_ANSWER_SUCCESS: undefined,
    UPDATE_ENTRY_ANSWER_ERROR: undefined,

    VALIDATE_ENTRYFORM_LOADING: undefined,
    VALIDATE_ENTRYFORM_SUCCESS: undefined,
    VALIDATE_ENTRYFORM_ERROR: undefined,

    ADD_PROJECT_LOADING: undefined,
    ADD_PROJECT_SUCCESS: undefined,
    ADD_PROJECT_ERROR: undefined,

    UPDATE_SUBMISSION_LOADING: undefined,
    UPDATE_SUBMISSION_SUCCESS: undefined,
    UPDATE_SUBMISSION_ERROR: undefined,

    GET_ENTRY_SCORES_LOADING: undefined,
    GET_ENTRY_SCORES_SUCCESS: undefined,
    GET_ENTRY_SCORES_ERROR: undefined,

    UPDATE_ENTRY_SCORE_LOADING: undefined,
    UPDATE_ENTRY_SCORE_SUCCESS: undefined,
    UPDATE_ENTRY_SCORE_ERROR: undefined,

    UPLOAD_IMAGE_LOADING: undefined,
    UPLOAD_IMAGE_SUCCESS: undefined,
    UPLOAD_IMAGE_ERROR: undefined,


    CLEAR_ENTRY_MESSAGE: undefined,



    // common
    GET_COUNTRIES_LOADING: undefined,
    GET_COUNTRIES_SUCCESS: undefined,
    GET_COUNTRIES_ERROR: undefined,

    GET_CITIES_LOADING: undefined,
    GET_CITIES_SUCCESS: undefined,
    GET_CITIES_ERROR: undefined,

    GET_AWARD_CATEGORY_LOADING: undefined,
    GET_AWARD_CATEGORY_SUCCESS: undefined,
    GET_AWARD_CATEGORY_ERROR: undefined,

    GET_DEVELOPMENT_TYPE_LOADING: undefined,
    GET_DEVELOPMENT_TYPE_SUCCESS: undefined,
    GET_DEVELOPMENT_TYPE_ERROR: undefined,

    GET_LOCATIONS_LOADING: undefined,
    GET_LOCATIONS_SUCCESS: undefined,
    GET_LOCATIONS_ERROR: undefined,

    GET_RESIDENTIAL_LOADING: undefined,
    GET_RESIDENTIAL_SUCCESS: undefined,
    GET_RESIDENTIAL_ERROR: undefined,

    GET_GREEN_TYPE_LOADING: undefined,
    GET_GREEN_TYPE_SUCCESS: undefined,
    GET_GREEN_TYPE_ERROR: undefined,

    GET_AWARD_CRITERIA_LOADING: undefined,
    GET_AWARD_CRITERIA_SUCCESS: undefined,
    GET_AWARD_CRITERIA_ERROR: undefined,

    GET_CSV_LOADING: undefined,
    GET_CSV_SUCCESS: undefined,
    GET_CSV_ERROR: undefined,

    GET_HOMEPAGE_CONTENT_LOADING: undefined,
    GET_HOMEPAGE_CONTENT_SUCCESS: undefined,
    GET_HOMEPAGE_CONTENT_ERROR: undefined,

    GET_NON_DEVELOPER_AWARD_CATEGORY_LOADING: undefined,
    GET_NON_DEVELOPER_AWARD_CATEGORY_SUCCESS: undefined,
    GET_NON_DEVELOPER_AWARD_CATEGORY_ERROR: undefined,

    GET_CURRENCIES_LOADING: undefined,
    GET_CURRENCIES_SUCCESS: undefined,
    GET_CURRENCIES_ERROR: undefined,

    SET_CURRENCY_SELECTED_SUCCESS: undefined,

    GET_MEASUREMENT_LOADING: undefined,
    GET_MEASUREMENT_SUCCESS: undefined,
    GET_MEASUREMENT_ERROR: undefined,

    SET_MEASUREMENT_SELECTED_SUCCESS: undefined,

    DELETE_MEDIA_LOADING: undefined,
    DELETE_MEDIA_SUCCESS: undefined,
    DELETE_MEDIA_ERROR: undefined,

    GET_AWARD_TYPES_LOADING: undefined,
    GET_AWARD_TYPES_SUCCESS: undefined,
    GET_AWARD_TYPES_ERROR: undefined,

    GET_GRANDFINAL_CATEGORIES_LOADING: undefined,
    GET_GRANDFINAL_CATEGORIES_SUCCESS: undefined,
    GET_GRANDFINAL_CATEGORIES_ERROR: undefined,

    GET_ENTRY_AWARD_SCORES_LOADING: undefined,
    GET_ENTRY_AWARD_SCORES_SUCCESS: undefined,
    GET_ENTRY_AWARD_SCORES_ERROR: undefined,

    REUSE_ENTRY_LOADING: undefined,
    REUSE_ENTRY_SUCCESS: undefined,
    REUSE_ENTRY_ERROR: undefined,

}



const getTypes = () => {
    let obj: any = {}
    Object.keys(Types).forEach(t => {
        obj[t] = t
    })
    return obj;
}


export default getTypes();